import React from 'react';

import { Radio } from 'antd'
import { DatePicker, List } from 'antd-mobile'

const RadioGroup = Radio.Group
class BackDate extends React.Component {
    state = {
        backDate: null,
        radioValue: 0
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.props.value) {
            if (nextProps.value != 99) {
                this.setState({ backDate: nextProps.value })
            } else {
                this.setState({
                    backDate: null,
                    radioValue: 99
                })
            }
        }
    }
    onDateChange = (v) => {
        console.log('onDateChange', v)
        this.setState({
            backDate: v
        })
        this.props.onChange(v)
    }
    onChange = (e) => {
        const v = e.target.value
        this.setState({
            radioValue: v
        })
        if (v == 99)
            this.props.onChange(v)
        else
            this.props.onChange('')
    }
    render() {
        const disabled = this.props.disabled
        return (
            <RadioGroup value={this.state.radioValue} onChange={this.onChange} disabled={disabled} >
                <Radio value={0} className="inline-radio" >
                    <DatePicker disabled={disabled} value={this.state.backDate} maxDate={new Date()} minDate={new Date('2019/12/1')} extra={<span style={{ color: '#bbb' }}>请选择日期</span>} onChange={this.onDateChange} mode="date">
                        <List.Item arrow="horizontal" style={{ display: 'inline-block', border: 'none' }}></List.Item>
                    </DatePicker>
                </Radio>
                <Radio value={99}>其他</Radio>
            </RadioGroup>
        )
    }
}
export default BackDate;
