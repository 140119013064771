import React from 'react';

export default class RedirectPage extends React.Component {
    constructor(props) {
        super(props)
    }
    componentDidMount() {
        localStorage.setItem('isMiniFrom', true)
        const local = window.location
        //生产环境
        let indexUrl = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx30f2b51d9d68085d&redirect_uri=https%3A%2F%2Fm.yunmaolink.com%2F%3Fregioncode%3D310112%26regiontype%3D3%26batchno%3D1&response_type=code&scope=snsapi_base&state=1541585539630&connect_redirect=1#wechat_redirect',
            dailyUrl = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx30f2b51d9d68085d&redirect_uri=https%3A%2F%2Fm.yunmaolink.com%2Fdaily&response_type=code&scope=snsapi_base&state=1541585539630&connect_redirect=1#wechat_redirect'
        if (local.host.includes('test')) {
            // 测试环境
            indexUrl = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx4dda731f59fb53f6&redirect_uri=http%3A%2F%2Ftest-m.yunmaolink.com%2F%3Fregioncode%3D310112%26regiontype%3D3%26batchno%3D11&response_type=code&scope=snsapi_base&state=1541585539630&connect_redirect=1#wechat_redirect'
            dailyUrl = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx4dda731f59fb53f6&redirect_uri=http%3A%2F%2Ftest-m.yunmaolink.com%2Fdaily&response_type=code&scope=snsapi_base&state=1541585539630&connect_redirect=1#wechat_redirect'
        }
               // let indexUrl = 'http://192.168.3.13:3000'
               //  let dailyUrl = 'http://192.168.3.13:3000'
        if (local.pathname.includes('wx_index')) {
            window.location.href = indexUrl
        } else if (local.pathname.includes('wx_daily')) {
            window.location.href = dailyUrl
        }
    }
    render() {
        return <div>
            请稍后...
        </div>
    }
}