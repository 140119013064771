import React from 'react';
import { Radio, Icon } from 'antd'

import { InputItem, Picker, List, DatePicker, Button, Toast, Modal } from 'antd-mobile'
import { createForm } from 'rc-form';

import { getAreas } from '../api'

import dayjs from 'dayjs'

import Label from '../home/Label'
import BackDate from '../home/BackDate'

import 'antd/es/radio/style/css'
import 'antd/es/icon/style/css'

const alert = Modal.alert;
const RadioGroup = Radio.Group

function getAsyncDataNode(asyncData = [], asyncValue = []) {
    console.log('getAsyncDataNode', asyncValue, asyncData)
    let data = null, arr = asyncData
    for (let i = 0; i < asyncValue.length; i++) {
        data = arr.find(n => n.value === asyncValue[i])
        if (!data) {
            return null
        } else {
            arr = data.children || [];
        }
    }
    return data
}

const asynCode = ['provinceCode', 'cityCode', 'areaCode', 'streetCode', 'villageCode', 'communityCode']

class Info extends React.Component {

    constructor(props) {
        super(props)
        this.state = { asyncData: [], asyncValue: [], asyncError: false, cols: 1, showBase: false }
        if (props.data.dailyPersonInfo) {
            let formdata = { ...this.props.data.dailyPersonInfo }

            
            if(!formdata.backDate){
                formdata.backDate =99
            }else{
                formdata.backDate = new Date(dayjs(formdata.backDate).format('YYYY/MM/DD 00:00:00'))
                formdata.isolateEndDate = new Date(dayjs(formdata.isolateEndDate).format('YYYY/MM/DD'))
            }
            
            formdata.gender = +formdata.gender
            let asyncValue = []
            asynCode.forEach((c) => {
                if (formdata[c]) {
                    asyncValue.push(formdata[c])
                }
            })

            this.state.asyncValue = asyncValue
            this.state.showBase = false
            this.initialAsyncData()
            setTimeout(() => {
                props.form.setFieldsValue(formdata)
            }, 500)


        } else {
            this.state.showBase = true
            this.getAsyncData('0')
        }
    }
    getAsyncData = (queryCode, asyncData = [], asyncValue = [], setValue = false) => {
        getAreas({ queryCode }, this.props.header).then(res => {
            const tempVal = setValue ? asyncValue : []
            if (res.status != 0 || res.data.dataList.length === 0) {
                console.log(asyncData, asyncValue)
                this.setState({
                    asyncData,
                    asyncValue: tempVal,
                    cols: asyncValue.length,
                    asyncDefaultValue: asyncValue
                })
                return;
            };
            let resData = res.data.dataList
            resData.forEach(n => {
                n.value = n.regionCode
                n.label = n.regionName
                n.children = []
            })
            if (queryCode === '0') {
                this.getAsyncData(resData[0].value, resData, [resData[0].value])
            } else {
                let node = getAsyncDataNode(asyncData, asyncValue)
                if (node) {
                    node.children = resData
                    asyncValue.push(resData[0].value)
                    this.getAsyncData(resData[0].value, asyncData, asyncValue, setValue)
                }
            }
        })
    }
    initialAsyncData = () => {

        const asyncValue = [].concat(this.state.asyncValue)

        const headers = this.props.header
        asyncValue.pop()
        const qarr = []
        qarr.push(getAreas({ queryCode: '0' }, headers))
        asyncValue.forEach(t => {
            qarr.push(getAreas({ queryCode: t }, headers))
        })

        Promise.all(qarr).then(res => {
            res.forEach(r => {
                r.data.dataList.forEach(n => {
                    n.value = n.regionCode
                    n.label = n.regionName
                    n.children = []
                })
            })
            const root = res[0].data.dataList

            let parent = root
            asyncValue.forEach((t, index) => {
                let node = parent.find(d => d.value === t)
                node.children = res[index + 1].data.dataList
                parent = node.children
            })
            console.log(root)
            this.setState({
                asyncData: root,
                cols: asyncValue.length + 1
            })

        })

    }
    onPickerChange = (val) => {
        const asyncValue = [...val];
        console.log('onPickerChange', asyncValue)
        this.getAsyncData(val[val.length - 1], [].concat(this.state.asyncData), asyncValue, true)
    }
    onPickerChange2 = (val) => {
        const asyncValue = [...this.state.asyncValue.slice(0, 3), ...val];
        console.log('onPickerChange2', asyncValue)
        this.getAsyncData(val[val.length - 1], [].concat(this.state.asyncData), asyncValue, true)
    }
    onAsyncOk = (v) => {

        this.setState({ asyncValue: this.state.asyncDefaultValue })
    }
    onAsyncCancel = () => {
        if (this.state.asyncValue.length == 0) {
            this.setState({
                asyncError: true
            })
        }
    }
    submit = () => {

        this.props.form.validateFields((error, values) => {
            if (this.state.asyncValue.length == 0) {
                Toast.info('您还有必填项没有填写！', 2);
                this.setState({
                    asyncError: true
                })
                return;
            }
            if (error) {
                Toast.info('您还有必填项没有填写！', 2);
            } else {

                if (this.props.data.dailyPersonInfo) {
                    let params = { ...values }
                    let { asyncValue } = this.state

                    asyncValue.forEach((v, index) => {
                        params[asynCode[index]] = v
                    })

                    if (params.backDate === 99) params.backDate = null
                    this.props.saveData(params)

                } else {
                    alert('确认提交', '请确保信息真实有效，开始每日打卡后个人基本信息不可更改', [
                        { text: '取消', onPress: () => console.log('cancel') },
                        {
                            text: '确认', onPress: () => {
                                let params = { ...values }
                                let { asyncValue } = this.state

                                asyncValue.forEach((v, index) => {
                                    params[asynCode[index]] = v
                                })

                                if (params.backDate === 99) params.backDate = null
                                this.props.saveData(params)
                            }
                        },
                    ])
                }
            }
        });
    }
    render() {
        const { data } = this.props
        const { getFieldProps, getFieldError, getFieldValue, setFi } = this.props.form;
        const { asyncData, asyncValue, asyncError, cols, showBase } = this.state
        console.log('render showBase', showBase, data)
        const backDate = getFieldValue('backDate')
        const validateIsolateDate = (rule, value, cb) => {
            if (backDate && backDate !== 99) {
                if (!dayjs(dayjs(backDate).format('YYYY-MM-DD 00:00:00')).add(13, 'day').isBefore(dayjs(dayjs(value).format('YYYY-MM-DD 01:00:00')))) {
                    cb(`隔离结束日期需大于返回日期14天`)
                    return;
                }
            }
            cb()
        }
        let index = 1
        const dailyArr = []
        // data.nowDate = '2020-02-16'
        if (!data.dailyPersonInfo) {
            dailyArr.push({
                type: 'now'
            })
            for (let i = 0; i < 13; i++) {
                dailyArr.push({
                    type: ''
                })
            }
        } else {
            let current
            if (data.dailyPersonInfo.backDate) {
                current = dayjs(data.dailyPersonInfo.backDate)
            } else {
                current = dayjs(data.checkDateList[0])
            }

            for (let i = 0; i < 14; i++) {
                if (current.format('YYYY-MM-DD') == dayjs(data.nowDate).format('YYYY-MM-DD')) {
                    dailyArr.push({
                        type: 'now'
                    })
                } else {
                    if (data.checkDateList.indexOf(current.format('YYYY-MM-DD')) > -1) {
                        dailyArr.push({
                            type: 'checked'
                        })
                    } else {
                        if (current.isBefore(dayjs(data.nowDate))) {
                            dailyArr.push({
                                type: 'disabled'
                            })
                        } else {
                            dailyArr.push({
                                type: ''
                            })
                        }
                    }
                }
                current = current.add(1, 'day')
            }

        }
        let line2
        if (asyncValue.length >= 3) {
            line2 = getAsyncDataNode(asyncData, asyncValue.slice(0, 3))
        }

        const disabled = data.dailyPersonInfo

        return (
            <div className="info" >
                <div className="daily-banner"></div>
                <div className="message">
                    <div className="title">每日健康打卡</div>
                    <div className="desc">各位居民住户：<br />
                        目前全国多地区发现新型冠状病毒感染病例，为避免疫情进一步传播，返回本市后开始14日居家隔离期间。请各位即日起配合每日填写健康打卡。以下数据，仅用于观察监控状况，不会对外泄露。
                    </div>
                    <div className="record">
                        <div className="record-title"><span>打卡记录</span></div>

                        <div className="records">
                            {dailyArr.slice(0, 7).map((d, index) => {
                                return <div key={index} className={'point ' + d.type}>{index + 1}<div className="circle"><Icon type="check-circle" theme="filled" /></div></div>
                            })}
                        </div>
                        <div className="records">
                            {dailyArr.slice(7).map((d, index) => {
                                return <div key={index} className={'point ' + d.type}>{index + 8}<div className="circle"><Icon type="check-circle" theme="filled" /></div></div>
                            })}
                        </div>

                    </div>
                </div>

                <div style={{ padding: '15px 0' }}>
                    <div className="block">
                        <Label index="" label="基本信息" style={{ fontSize: '1.2em' }}></Label>
                        <Icon
                            className="icon-ishow"
                            type={showBase ? 'up' : 'down'}
                            onClick={() => {
                                this.setState({ showBase: !showBase })
                            }}
                        />
                    </div>
                    <div style={{ display: (showBase ? 'block' : 'none') }}>
                        <div className="block">
                            <Label index={index++} label="姓名" required={true}></Label>
                            <InputItem
                                {...getFieldProps('name', {
                                    rules: [{ required: true }],
                                })}
                                placeholder="请填写"
                                disabled={disabled}
                            ></InputItem>
                            {getFieldError('name') ? <span className="error">该问题为必填！</span> : null}
                        </div>
                        <div className="block">
                            <Label index={index++} label="性别" required={true}></Label>
                            <RadioGroup
                                {...getFieldProps('gender', {
                                    rules: [{ required: true }],
                                    initialValue: 1
                                })}
                                disabled={disabled}
                            >
                                <Radio value={1}>男</Radio>
                                <Radio value={2}>女</Radio>
                            </RadioGroup>
                        </div>
                        <div className="block">
                            <Label index={index++} label="年龄" required={true}></Label>
                            <InputItem
                                {...getFieldProps('age', {
                                    rules: [{ required: true }],
                                })}
                                placeholder="请填写"
                                type="number"
                                disabled={disabled}
                            ></InputItem>
                            {getFieldError('age') ? <span className="error">该问题为必填！</span> : null}
                        </div>
                        <div className="block">
                            <Label index={index++} label="手机号码" required={true}></Label>
                            <InputItem
                                {...getFieldProps('phoneNo', {
                                    rules: [{ required: true }, { pattern: /^1[0-9]{10}$/ }],
                                })}
                                placeholder="请填写"
                                disabled={disabled}
                            ></InputItem>
                            {getFieldError('phoneNo') ? <span className="error">该问题为必填,并且是11位手机号！</span> : null}
                        </div>
                        <div className="block">
                            <Label index={index++} label="居住地" required={true}></Label>
                            <Picker extra={<span style={{ color: '#bbb' }}>请选择</span>}
                                data={asyncData}
                                title="居住地"
                                value={cols > 3 ? asyncValue.slice(0, 3) : asyncValue}
                                cols={cols > 3 ? 3 : cols}
                                onPickerChange={this.onPickerChange}
                                onOk={this.onAsyncOk}
                                onDismiss={this.onAsyncCancel}
                            >
                                <List.Item arrow="horizontal"></List.Item>
                            </Picker>
                            {cols > 3 && line2 && (<div>
                                <Picker extra={<span style={{ color: '#bbb' }}>请选择</span>}
                                    data={line2.children}
                                    title="居住地"
                                    value={asyncValue.slice(3)}
                                    cols={cols - 3}
                                    onPickerChange={this.onPickerChange2}
                                    onOk={this.onAsyncOk}
                                    onDismiss={this.onAsyncCancel}
                                >
                                    <List.Item arrow="horizontal"></List.Item>
                                </Picker>
                            </div>)}
                            {asyncError ? <span className="error">该问题为必填！</span> : null}
                        </div>
                        <div className="block">
                            <Label index={index++} label="详细地址" required={true}></Label>
                            <InputItem
                                {...getFieldProps('address', {
                                    rules: [{ required: true }],
                                })}
                                placeholder="请准确填写XX路XX号XX室"
                            ></InputItem>
                            {getFieldError('address') ? <span className="error">该问题为必填！</span> : null}
                        </div>
                        <div className="block">
                            <Label index={index++} label="返回本地后是否一直居家隔离" required={true}></Label>
                            <RadioGroup
                                {...getFieldProps('isolate', {
                                    initialValue: true
                                })}
                                disabled={disabled}
                            >
                                <Radio value={true}>是</Radio>
                                <Radio value={false}>否</Radio>
                            </RadioGroup>
                        </div>
                        <div className="block" >
                            <Label index={index++} label="外省返回本地日期" required={true}></Label>
                            <div className="description">未离开本地的可不选日期，直接勾选“其他”</div>
                            <BackDate
                                {...getFieldProps('backDate', {
                                    rules: [{ required: true }],
                                })}
                                disabled={disabled}
                            ></BackDate>
                            {getFieldError('backDate') ? <span className="error">该问题为必填！</span> : null}
                        </div>
                        {getFieldValue('backDate') != 99 && <div className="block" disabled={disabled}>
                            <Label index={index++} label="14天居家隔离结束日期" required={true}></Label>
                            <div className="description">如已解除隔离期也需填写</div>
                            <DatePicker
                                mode="date"
                                extra={<span style={{ color: '#bbb' }}>请选择日期</span>}
                                {...getFieldProps('isolateEndDate', {
                                    rules: [
                                        { required: true },
                                        {
                                            validator: validateIsolateDate
                                        }
                                    ],
                                })}
                                disabled={disabled}
                            >
                                <List.Item arrow="horizontal">请选择日期</List.Item>
                            </DatePicker>
                            {getFieldError('isolateEndDate') ? <span className="error">该问题为必填,并且大于返回本地日期14天！</span> : null}
                        </div>}
                    </div>
                    <div className="block">
                        <Label index="" label="健康状况" style={{ fontSize: '1.2em' }}></Label>
                    </div>

                    <div className="block">
                        <Label index={index++} label="目前体温" required={true}></Label>
                        <RadioGroup
                            {...getFieldProps('temperatureStatus', {
                                rules: [{ required: true }],
                                initialValue: 0
                            })}
                        >
                            <Radio value={0}>低于37.3℃</Radio>
                            <Radio value={1}>高于37.3℃</Radio>
                        </RadioGroup>
                    </div>
                    <div className="block">
                        <Label index={index++} label="有无咳嗽症状" required={true}></Label>
                        <RadioGroup
                            {...getFieldProps('coughStatus', {
                                rules: [{ required: true }],
                                initialValue: 0
                            })}
                        >
                            <Radio value={0}>无咳嗽症状</Radio>
                            <Radio value={1}>有咳嗽症状</Radio>
                        </RadioGroup>
                    </div>
                    <div className="block">
                        <Label index={index++} label="有无气促症状" required={true}></Label>
                        <RadioGroup
                            {...getFieldProps('breathStatus', {
                                rules: [{ required: true }],
                                initialValue: 0
                            })}
                        >
                            <Radio value={0}>无气促症状</Radio>
                            <Radio value={1}>有气促症状</Radio>
                        </RadioGroup>
                    </div>
                    <div className="block">
                        <Label index={index++} label="有无乏力症状" required={true}></Label>
                        <RadioGroup
                            {...getFieldProps('weakStatus', {
                                rules: [{ required: true }],
                                initialValue: 0
                            })}
                        >
                            <Radio value={0}>无乏力症状</Radio>
                            <Radio value={1}>有乏力症状</Radio>
                        </RadioGroup>
                    </div>
                    <div>
                        <Button type="primary" onClick={this.submit} style={{ margin: '60px' }}>提交</Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default createForm()(Info);
