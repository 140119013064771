import axios from 'axios'
const urlBase = '/ig-community'
export const getDailyCheck = (params, headers ={}) => {
    return axios.post(urlBase + '/web/daily/validateDailyCheck', params, {
        headers: headers
    }).then(res => {
        return res.data
    })
}
export const getAreas = (params, headers={})=>{
    return axios.post(urlBase + '/web/queryArea', params, {
        headers: headers
    }).then(res => {
        return res.data
    })
}