import React from 'react';

import '../index.css'

import { Result, Toast } from 'antd-mobile'

import axios from 'axios'

import Info from './Info'

import SuccessImg from '../image/success.png'
import FailedImg from '../image/failed.png'
import RemindImg from '../image/reminder.png'
import wx from 'weixin-js-sdk'

// const urlBase = 'http://route.igaicloud.cn:8088/ig-community'
const urlBase = '/ig-community'
// const urlBase = ''
function getUrlParam(paramName) {
  let q = {};
  window.location.search.replace(/([^?&=]+)=([^&]+)/g, (_, k, v) => q[k] = v);
  return q[paramName] || '';
}

function fomateRegion(list, root) {
  let tree = []
  let codelength = 0
  let cols = 0
  list.forEach(node => {
    node.value = node.regionCode
    node.label = node.regionName
    node.children = []
    if (codelength != node.value.length) {
      cols += 1
      codelength = node.value.length
    }
    if (tree.length == 0) {
      tree.push(node)
    } else {
      let parent = list.find(t => t.regionCode === node.parentCode)
      if (parent) {
        parent.children.push(node)
      } else {
        tree.push(node)
      }
    }
  })
  tree.push({
    label: '以上都不是',
    value: -1,
    children: []
  })
  return {
    regions: tree,
    cols
  }
}

const myImg = src => <img src={src} className="spe am-icon am-icon-md" alt="" />;
const testWxId = "od7enwvpynBL2RZwUoeHICv5PqUQ"
const testAT = '30_WSar9mVbpSuEx89kjM6DtTID4-ZzCCYpxQYZvlIJBPj_H2XoJ-RmGl3lP4q-OdCoRli0L8tb9SvfR3dH5Rvb5g'
class App extends React.Component {
  state = {
    loading: true,
    inputed: false,
    saved: 0,
    regions: [],
    cols: 3,
    wxId: testWxId,
    header: {
      wxId: testWxId,
      token: testAT
    }
  }
  componentDidMount() {
    // 是否来自于小程序
    const isMiniProgram = localStorage.getItem('isMiniFrom')
    const code = getUrlParam('code')
    let ticket = ''
    if (code) {
      axios.post(urlBase + '/web/queryWxInfo', {
        code
      }).then(res => {
        if (res.data.status == 0) {
          if (res.data.data.errcode) {
            Toast.info('无法获取到微信Openid，请使用微信扫码！', 2);
            this.setState({
              saved: 4,
              loading: false
            })
            return;
          }
          const wxId = res.data.data.openid
          const token = res.data.data.access_token
          axios.post(urlBase + '/web/validateWxId', {
            wxId,
            batchNo: getUrlParam('batchno') || 1,
            regionCode: getUrlParam('regioncode')
          }, {
            headers: {
              wxId,
              token
            }
          }).then(res => {
            if (res.data.data.validateResult) {
              this.setState({
                wxId,
                header: {
                  wxId,
                  token
                },
                loading: false
              }, () => {
                this.getRegions({
                  code: getUrlParam('regioncode'),
                  regionType: getUrlParam('regiontype')
                })
              })
            } else {
              this.setState({ saved: 3, loading: false })
            }
          })
          // 获取ticket
            axios.get('https://api.weixin.qq.com/cgi-bin/ticket/getticket', {
              params: {
                access_token: token,
                type: 'jsapi'
              }
            })
              .then(res => res.data)
              .then(res => {
                if (res.errcode == '0') {
                  ticket = res.ticket
                  // localStorage.setItem('jsapi_ticket', JSON.stringify({
                  //   ticket: res.ticket,
                  //   expires_in: res.expires_in,
                  //   tp: new Date().valueOf()
                  // }))
                }
                return res
              })

        } else {
          this.setState({
            saved: 4,
            loading: false
          })
        }
      }).catch(() => {
        this.setState({
          saved: 4,
          loading: false
        })
      })
    } else {
      axios.post(urlBase + '/web/validateWxId', {
        wxId: testWxId,
        batchNo: getUrlParam('batchno') || 1,
        regionCode: 310112,
        regionType: 3
      }, {
        headers: this.state.header
      }).then(res => {
        // res.data.data.validateResult true
        if (true) {
          this.setState({
            wxId: testWxId,
            loading: false
          })
          this.getRegions({
            code: '350524',
            regionType: 3
          })
        } else {
          this.setState({ saved: 3, loading: false })
        }

      }).catch(() => {
        this.setState({
          saved: 4,
          loading: false
        })
      })

    }
    if (isMiniProgram) {
      // 小程序
      function pushHistory(){
        var state={
          title:"title",
          url:"#"
        };
        window.history.pushState(state,"title","#");
      }
      pushHistory()
      // 监听回退
      window.addEventListener('popstate', function(e) {
        wx.miniProgram.navigateBack({
          delta: 1
        })
      }, false);
    }

  }

    componentWillUnmount() {
        localStorage.removeItem('isMiniFrom')
    }

  getRegions = (params) => {
    axios.post(urlBase + '/web/queryAreaTree', params, {
      headers: this.state.header
    }).then(res => {
      if (res.data.status == 0) {
        this.setState({
          ...fomateRegion(res.data.data.dataList, params.code)
        })
      }
    }).catch(() => {
      this.setState({
        saved: 4,
        loading: false
      })
    })
  }
  onSave = (values) => {
    axios.post(urlBase + '/web/savePersonInfo', {
      ...values,
      wxId: this.state.wxId,
      regionCode: getUrlParam('regioncode'),
      batchNo: getUrlParam('batchno') || 1
    }, {
      headers: this.state.header
    }).then(res => {
      if (res.data.status == 0) {
        this.setState({
          saved: 1
        })
      } else {
        this.setState({
          saved: 2
        })
      }
    }).catch(() => {
      this.setState({
        saved: 4,
        loading: false
      })
    })
  }
  render() {
    const { loading, saved, regions, cols } = this.state
    if (loading) return <div style={{ margin: '20% 0', textAlign: 'center' }}>正在准备数据...</div>
    if (saved == 1) return (
      <Result
        img={myImg(SuccessImg)}
        title="提交成功"
      />
    )
    if (saved == 2) return (
      <Result
        img={myImg(FailedImg)}
        title="提交失败"
      />
    )
    if (saved == 3) return (
      <Result
        img={myImg(RemindImg)}
        title="您已提交填表，感谢您的支持！"
      />
    )

    if (saved == 4) return (
      <Result
        img={myImg(FailedImg)}
        title="出现错误"
      />
    )
    return (
      <div className="App">
        <Info saveData={this.onSave} regions={regions} regionLevel={cols} header={this.state.header} />
      </div>
    );
  }
}

export default App;
