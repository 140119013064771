import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

import Home from './home/Home'
import Daily from './daily/Daily'
import RedirectPage from './home/RedirectPage'
import Vconsole from 'vconsole'
import 'antd-mobile/dist/antd-mobile.css';
// new Vconsole()

function App() {
    return (
        <Router>
            <div>
                <Switch>
                    <Route exact path="/">
                        <Home/>
                    </Route>
                    <Route path="/daily">
                        <Daily/>
                    </Route>
                    {/* 健康信息报备 */}
                    <Route path="/wx_index">
                        <RedirectPage/>
                    </Route>
                    {/* 每日健康打卡 */}
                    <Route path="/wx_daily">
                        <RedirectPage/>
                    </Route>
                </Switch>
            </div>
        </Router>
    );
}

export default App;
