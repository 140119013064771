import React from 'react';



import { Result, Toast } from 'antd-mobile'

import axios from 'axios'
import { getDailyCheck } from '../api'

import Info from './Info'

import SuccessImg from '../image/success.png'
import FailedImg from '../image/failed.png'
import RemindImg from '../image/reminder.png'

import '../index.css'
import './daily.css'
import wx from 'weixin-js-sdk'

// const urlBase = 'http://route.igaicloud.cn:8088/ig-community'
const urlBase = '/ig-community'
// const urlBase = ''
function getUrlParam(paramName) {
    let q = {};
    window.location.search.replace(/([^?&=]+)=([^&]+)/g, (_, k, v) => q[k] = v);
    return q[paramName] || '';
}


const myImg = src => <img src={src} className="spe am-icon am-icon-md" alt="" />;
const testWxId = "od7enwvpynBL2RZwUoeHICv5PqUQ"
const testAT = '30_7TEitVaytHI8KrR2LRKF9I0O0DPTBNTFvjMAvOwvXux5NavI3y2qtXlRd5F_z35nSyRbgeBxCaEPEKIyD5c1nQ'

const SaveStatus = {
    1: {
        message: '提交成功',
        image: SuccessImg,
    },
    2: {
        message: '提交失败，请重试',
        image: FailedImg,
    },
    3: {
        message: '亲爱的居民朋友，您今天填写过健康打卡表了，请明天准时来打卡',
        image: RemindImg
    },
    4: {
        message: "出现错误",
        image: RemindImg
    },
    5: {
        message: "每日00:00-06:00不可打卡,当前还未到每日健康打卡时间请早上6:00后再来填写",
        image: RemindImg
    }
}

class App extends React.Component {
    state = {
        loading: true,
        inputed: false,
        saved: 0,
        wxId: testWxId,
        header: {
            wxId: testWxId,
            token: testAT
        },
        data: {},
    }
    componentDidMount() {
        // 是否来自于小程序
        const isMiniProgram = localStorage.getItem('isMiniFrom')
        const code = getUrlParam('code')

        if (code) {
            axios.post(urlBase + '/web/queryWxInfo', {
                code
            }).then(res => {
                if (res.data.status == 0) {
                    if (res.data.data.errcode) {
                        Toast.info('无法获取到微信Openid，请使用微信扫码！', 2);
                        this.setState({
                            saved: 4,
                            loading: false
                        })
                        return;
                    }
                    const wxId = res.data.data.openid
                    const token = res.data.data.access_token
                    this.setState({
                        wxId,
                        header: {
                            wxId: wxId,
                            token: token
                        }
                    })
                    getDailyCheck({
                        wxId: wxId
                    }, {
                        wxId,
                        token
                    }).then(res => {
                        if (res.data.alreadyCheck) {
                            this.setState({
                                loading: false,
                                saved: 3
                            })
                        } else if (!res.data.canIDailyCheck) {
                            this.setState({
                                loading: false,
                                saved: 5
                            })
                        } else {
                            this.setState({
                                loading: false,
                                data: res.data
                            })
                        }
                    })
                } else {
                    this.setState({
                        saved: 4,
                        loading: false
                    })
                }
            }).catch(() => {
                this.setState({
                    saved: 4,
                    loading: false
                })
            })
        } else {
            getDailyCheck({
                wxId: testWxId
            }, {
                wxId: testWxId,
                token: testAT
            }).then(res => {
                if (res.data.alreadyCheck) {
                    this.setState({
                        loading: false,
                        saved: 3
                    })
                } else if (!res.data.canIDailyCheck) {
                    this.setState({
                        loading: false,
                        saved: 5
                    })
                } else {
                    this.setState({
                        loading: false,
                        data: res.data
                    })
                }

            }).catch(() => {
                this.setState({
                    saved: 4,
                    loading: false
                })
            })

        }

        if (isMiniProgram) {
            // 小程序
            function pushHistory(){
                var state={
                    title:"title",
                    url:"#"
                };
                window.history.pushState(state,"title","#");
            }
            pushHistory()
            // 监听回退
            window.addEventListener('popstate', function(e) {
                wx.miniProgram.navigateBack({
                    delta: 1
                })
            }, false);
        }

    }
    componentWillUnmount() {
        localStorage.removeItem('isMiniFrom')
    }
    onSave = (values) => {

        axios.post(urlBase + '/web/daily/saveDailyInfo', {
            ...values,
            wxId: this.state.wxId,
            regionCode: getUrlParam('regioncode'),
            batchNo: getUrlParam('batchno') || 1
        }, {
            headers: this.state.header
        }).then(res => {
            if (res.data.status == 0) {
                this.setState({
                    saved: 1
                })
            } else {
                this.setState({
                    saved: 2
                })
            }
        }).catch(() => {
            this.setState({
                saved: 4,
                loading: false
            })
        })
    }
    render() {
        const { loading, saved, data } = this.state
        if (loading) return <div style={{ margin: '20% 0', textAlign: 'center' }}>正在准备数据...</div>
        if (saved > 0) {
            return (
                <Result
                    img={myImg(SaveStatus[saved].image)}
                    title={SaveStatus[saved].message}
                />
            )
        }

        return (
            <div className="App">
                <Info saveData={this.onSave} data={data} header={this.state.header} />
            </div>
        );
    }
}

export default App;
