import React from 'react';
import axios from 'axios'
import { Radio } from 'antd'
import 'antd/es/radio/style/css'
import { InputItem, Picker, List, DatePicker, Button, Toast } from 'antd-mobile'
import { createForm } from 'rc-form';

import dayjs from 'dayjs'

import Label from './Label'
import Label_a from './Label_a'
import BackDate from './BackDate'

// const urlBase = 'http://test-route.igaicloud.cn:8088/ig-community'
const urlBase = '/ig-community'
// const urlBase = ''
const RadioGroup = Radio.Group
class Info extends React.Component {
    state = { asyncData: [], asyncValue: [], asyncError: false, industryTypeValue: [] }
    componentDidMount() {
        this.getAsyncData('0')
    }
    getAsyncData = (queryCode, asyncData = [], asyncValue = [], setValue = false) => {
        axios.post(urlBase + '/web/queryArea', { queryCode }, {
            headers: this.props.header
        }).then(res => {
            if (res.data.status != 0) return;
            let resData = res.data.data.dataList
            resData.forEach(n => {
                n.value = n.regionCode
                n.label = n.regionName
                n.children = []
            })
            if (queryCode === '0') {
                this.getAsyncData(resData[0].value, resData, [resData[0].value])
            } else {
                let node = asyncData.find(t => t.regionCode === queryCode)
                if (node) {
                    node.children = resData
                    asyncValue.push(resData[0].value)
                }
                this.setState({
                    asyncData,
                    asyncValue: setValue ? asyncValue : []
                })
            }
        })
    }
    onPickerChange = (val) => {
        const asyncValue = [...val];
        console.log('onPickerChange', asyncValue)
        if (asyncValue.length == 2) return;
        this.getAsyncData(val[0], [].concat(this.state.asyncData), asyncValue, true)
    }
    onAsyncOk = (v) => {
        if (v.length == 2)
            this.setState({ asyncValue: v })
    }
    onAsyncCancel = () => {
        if (this.state.asyncValue.length == 0) {
            this.setState({
                asyncError: true
            })
        }
    }
    submit = () => {
        console.log('this.state.industryTypeValue: ', this.state.industryTypeValue)
        this.props.form.validateFields((error, values) => {
            if (this.state.asyncValue.length == 0) {
                Toast.info('您还有必填项没有填写！', 2);
                this.setState({
                    asyncError: true
                })
                return;
            }
            if (this.state.industryTypeValue.length == 0) {
                Toast.info('您还有必填项没有填写！', 2);
                this.setState({
                    asyncError: true
                })
                return;
            }
            if (error) { // error
                Toast.info('您还有必填项没有填写！', 2);
            } else {
                let params = { ...values }
                let { asyncData, asyncValue } = this.state
                console.log('asyncValue: ', asyncValue)
                console.log('asyncData: ', asyncData)
                let node = asyncData.find(t => t.value === asyncValue[0])
                console.log('node: ', node)
                let l2node = node.children.find(t => t.value = asyncValue[1])
                console.log('l2node: ', l2node)
                let city = ''
                let province = ''
                asyncData.forEach(o => {
                    if(o.value === asyncValue[0]) {
                        province = o.regionName
                        o.children.forEach(i => {
                            if(i.value === asyncValue[1]) {
                                city = i.regionName
                            }
                        })
                    }
                })
                console.log('city: ', city)
                console.log('l2node: ', l2node)
                params.fromAddress = province + '，' + city
                params.fromCityCode = asyncValue[1]
                params.fromProvinceCode = asyncValue[0]
                params.addressCode = params.addressCode.pop()
                params.companyType = this.state.industryTypeValue[0]
                if (params.backDate === 99) params.backDate = null
                this.props.saveData(params)
            }
        });
    }
    render() {
        const { regions, regionLevel } = this.props
        const { getFieldProps, getFieldError, getFieldValue } = this.props.form;
        const { asyncData, asyncValue, asyncError } = this.state
        // console.log('render', asyncValue)
        const backDate = getFieldValue('backDate')
        const validateIsolateDate = (rule, value, cb) => {
            if (backDate && backDate !== 99) {
                if (!dayjs(backDate).add(14, 'day').isBefore(dayjs(value))) {
                    cb(`隔离结束日期需大于返回日期14天`)
                    return;
                }
            }
            cb()
        }
        
        const industryTypeList = [
            {
                label: '教育',
                value: 1,
            },{
                label: '托育',
                value: 2,
            },{
                label: '医务',
                value: 3,
            },{
                label: '家政',
                value: 4,
            },{
                label: '护理',
                value: 5,
            },{
                label: '劳动密集型',
                value: 6,
            },{
                label: '物业',
                value: 7,
            },{
                label: '快递',
                value: 8,
            },{
                label: '公交',
                value: 9,
            },{
                label: '出租',
                value: 10,
            },{
                label: '其它',
                value: 0,
            }
        ]

        return (
            <div className="info">
                <div className="banner"></div>
                <div className="message">
                    <div className="title">外省市返乡人员信息健康登记表</div>
                    <div className="desc">各位居民及已返回本市县住户： <br />目前全国多地区发现新型冠状病毒感染病例，为避免病毒再进一步传播，请各位如实填写以下信息，我们会保护隐私，不对外公开。 如有困难可联系所在地居委会工作人员。</div>
                </div>
                <div style={{ padding: '15px 0' }}>
                    <div className="block">
                        <Label index={1} label="姓名" required={true}></Label>
                        <InputItem
                            {...getFieldProps('name', {
                                rules: [{ required: true }],
                            })}
                            placeholder="请填写"
                        ></InputItem>
                        {getFieldError('name') ? <span className="error">该问题为必填！</span> : null}
                    </div>
                    <div className="block">
                        <Label index={2} label="性别" required={true}></Label>
                        <RadioGroup
                            {...getFieldProps('gender', {
                                rules: [{ required: true }],
                                initialValue: 1
                            })}
                        >
                            <Radio value={1}>男</Radio>
                            <Radio value={2}>女</Radio>
                        </RadioGroup>
                    </div>
                    <div className="block">
                        <Label index={3} label="电话" required={true}></Label>
                        <InputItem
                            {...getFieldProps('phoneNo', {
                                rules: [{ required: true }, { pattern: /^1[0-9]{10}$/ }],
                            })}
                            placeholder="请填写"
                        ></InputItem>
                        {getFieldError('phoneNo') ? <span className="error">该问题为必填,并且是11位手机号！</span> : null}
                    </div>
                    <div className="block">
                        <Label index={4} label="居住地" required={true}></Label>
                        <Picker extra="请选择居住地"
                            data={regions}
                            title="居住地"
                            cols={regionLevel}
                            {...getFieldProps('addressCode', {
                                rules: [{ required: true }],
                            })}
                        >
                            <List.Item arrow="horizontal"></List.Item>
                        </Picker>
                        {getFieldError('addressCode') ? <span className="error">该问题为必填！</span> : null}
                    </div>
                    <div className="block">
                        <Label index={5} label="详细地址" required={true}></Label>
                        <InputItem
                            {...getFieldProps('address', {
                                rules: [{ required: true }],
                            })}
                            placeholder="请准确填写XX路XX号XX室"
                        ></InputItem>
                        {getFieldError('address') ? <span className="error">该问题为必填！</span> : null}
                    </div>
                    <div className="block">
                        <Label index={6} label="从哪里返回本地" required={true}></Label>
                        <Picker extra="请选择"
                            data={asyncData}
                            title="从哪里返回本地"
                            value={asyncValue}
                            cols={2}
                            onPickerChange={this.onPickerChange}
                            onOk={this.onAsyncOk}
                            onDismiss={this.onAsyncCancel}
                        // {...getFieldProps('fromAddress', {
                        //     rules: [{ required: true }],
                        // })}
                        >
                            <List.Item arrow="horizontal"></List.Item>
                        </Picker>
                        {asyncError ? <span className="error">该问题为必填！</span> : null}
                    </div>
                    <div className="block">
                        <Label index={7} label="返回本地后是否一直居家隔离" required={true}></Label>
                        <RadioGroup
                            {...getFieldProps('isolate', {
                                initialValue: true
                            })}
                        >
                            <Radio value={true}>是</Radio>
                            <Radio value={false}>否</Radio>
                        </RadioGroup>
                    </div>
                    <div className="block">
                        <Label index={8} label="外省返回本地日期" required={true}></Label>
                        <div className="description">未离开本地的可不选日期，直接勾选“其他”</div>
                        <BackDate
                            {...getFieldProps('backDate', {
                                rules: [{ required: true }],
                            })}
                        ></BackDate>
                        {getFieldError('backDate') ? <span className="error">该问题为必填！</span> : null}
                    </div>
                    {getFieldValue('backDate') != 99 && <div className="block">
                        <Label_a index={9} label="14天居家隔离结束日期" required={true}></Label_a>
                        <div className="description">如已解除隔离期也需填写</div>
                        <DatePicker
                            mode="date"
                            extra="请选择日期"
                            {...getFieldProps('isolateEndDate', {
                                rules: [
                                    { required: true },
                                    {
                                        validator: validateIsolateDate
                                    }
                                ],
                            })}
                        >
                            <List.Item arrow="horizontal">请选择日期</List.Item>
                        </DatePicker>
                        {getFieldError('isolateEndDate') ? <span className="error">该问题为必填,并且大于返回本地日期14天！</span> : null}
                    </div>}                    
                    <div className="block">
                        <Label index={backDate == 99 ? 9 : 10} label="请选择行业类型" required={true}></Label>
                        <div className="description">选择行业类型</div>
                        <div>
                            <Picker extra="请选择行业类型"
                                data={industryTypeList}
                                title="行业类型"
                                value={this.state.industryTypeValue}
                                cols={1}
                                onOk={e => {
                                    console.log('e: ', e)
                                    this.setState({industryTypeValue:e})
                                }}
                            >
                                <List.Item arrow="horizontal">请选择行业类型</List.Item>
                            </Picker>
                        </div>                
                    </div>
                    <div className="block">
                        <Label index={backDate == 99 ? 10 : 11} label="目前健康状况描述" required={true}></Label>
                        <div className="description" style={{ padddingLeft: 22 }}>是否有发热、乏力、咳嗽等相关症状</div>
                        <RadioGroup
                            {...getFieldProps('healthType', {
                                initialValue: 1
                            })}
                        >
                            <Radio value={1}>无相关感冒发热症状</Radio>
                            <Radio value={2}>体温高于37.3摄氏度</Radio>
                            <Radio value={3}>有咳嗽</Radio>
                            <Radio value={4}>有乏力</Radio>
                            <Radio value={5}>有以上多种症状</Radio>
                        </RadioGroup>
                    </div>
                    <div>
                        <Button type="primary" onClick={this.submit} style={{ margin: '60px' }}>提交</Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default createForm()(Info);
